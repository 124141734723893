import React from 'react';
import '../styles/Button.css';
import { FaPhoneAlt } from 'react-icons/fa'; // Assuming you are using react-icons

const Button = ({ text, link, icon }) => {
  return (
    <div className="button-container">
      <button className="button-with-image" onClick={() => link()}>
        {icon && <span className="button-icon">{icon}</span>}
        <span className="button-text">{text}</span>
      </button>
    </div>
  );
}

export default Button;
