import React, { useState } from "react";
import "../styles/contact.css";
import axios from "axios";
import cow2 from "../images/cow2.png";
import twitter from "../images/twitter.png";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import printest from "../images/printest.png";
import whatapp from "../images/whasapp.svg";

import Button from "./Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contactus = () => {
  const [formdata, setformdata] = useState({
    name: "",
    contact: "",
    email: "",
    subject: "",
    message: "",
  });

  const validateForm = () => {
    const { name, contact, email, subject, message } = formdata;
    if (!name || !contact || !email || !subject || !message) {
      toast.error("Please fill out all fields.");
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (validateForm()) {
      axios
        .post("https://api.kavlikart.com/mail", formdata)
        .then((response) => {
          console.log(response.data);
          setformdata({
            name: "",
            contact: "",
            email: "",
            subject: "",
            message: "",
          });

          toast.success("Form submitted successfully!");
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
          toast.error("Failed to submit the form. Please try again.");
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value,
    });
  };

  return (
    <>
      <div className="contact-us">
        <div className="contact-heading">
          <h1>Contact Us</h1>
        </div>
        <div className="cow2-flex">
          <div className="cow2">
            <img src={cow2} alt="" />
          </div>
          <div className="contact-form">
            <div className="form-flex">
              <div className="form-container">
                <div className="input-row">
                  <input
                    type="text"
                    name="name"
                    className="input-box"
                    placeholder="Your Name"
                    value={formdata.name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="contact"
                    className="input-box"
                    placeholder="Your Contact Number"
                    value={formdata.contact}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="input-row">
                  <input
                    type="email"
                    name="email"
                    className="input-box"
                    placeholder="Your Email"
                    value={formdata.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="subject"
                    className="input-box"
                    placeholder="Subject"
                    value={formdata.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <textarea
                  type="text"
                  placeholder="Message"
                  name="message"
                  className="input-box5"
                  value={formdata.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="button-submit">
                <Button
                  className="form-button"
                  text={"SUBMIT"}
                  link={submitForm}
                />
              </div>
              <div className="keep">
                <p>
                  Get in touch with us for premium A2 Ghee. Feel free to ask
                  about ghee we are just a call away.
                </p>
                <div className="keep-contact">
                  <p className="keep-contact-text">Contact:+919173828116</p>
                  <div className="keep-contact-text">
                    Email:advayaenterprise@gmail.com
                  </div>
                </div>
                <div className="all-icon">
                  <a
                    href="https://www.instagram.com/kavlikartghee/"
                    target="_blank"
                  >
                    <img src={instagram} alt="Instagram Profile" />
                  </a>
                  <a
                    href="https://www.facebook.com/people/KavliKart-Ghee/61552682545695/"
                    target="_blank"
                  >
                    <img src={facebook} alt="" />
                  </a>
                  {/* <a href="">
                    <img src={twitter} alt="" />
                  </a> */}
                  <a href="https://wa.me/9173828116" target="_blank">
                    <img src={whatapp} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Contactus;
