import React, { useState } from "react";
import "../styles/product.css";
import "../styles/Modal.css";
import abimg1 from "../images/abimg1.jpg";
import abimg2 from "../images/abimg2.jpg";
import eye from "../images/eye.png";
import ghee from "../images/bilona-ghee.png";
import pack4 from "../images/pack4.png";
import pack2 from "../images/Pack2.png";
import pack3 from "../images/pack3.png";
import pack5 from "../images/pack5.png";
import ltr from "../images/1ltr.png";
import fresh from "../images/newest.png";
import letest from "../images/latest-img.png";
import ml500 from "../images/500ml.png";
import Button from "./Button";
import Modal from "./Modal";
import Modaltwo from "./Modaltwo";
import cow from "../images/cow-ghee.png";
import whatapp from "../images/whasapp.svg";
import freshimg from "../images/freshimg.png";
import bone from "../images/bone.png";
import oftwo from "../images/OfTwo.png";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Product = () => {
  const settings = {
    dots: false,
    infinite: true,
    
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
  };
  
  
  const amazonLink =
    "https://www.amazon.in/bilona-gir-cow-ghee-1kg-A2-Curd-Churned-Vedic-Method/dp/B0CNYV222H/ref=sr_1_5?crid=1SXQOPT8PDX2V&dib=eyJ2IjoiMSJ9.tl3flArf6JEQ5TAqsCJ-MleMQSVXs1F8Hs0Hd3nt_46ooyTEE_Ht1yju3VEUHMIvnMvZ_sYNgx41xr_CTgJHkVdxTMnnHDJoO7vNvP444akyCnsfxCcGSYCjUbhPUHdJEI-MoJFPScdPgR8YVUmDLgVn6A3GywnrjyRFOxAHeQRXGaG2fOHSduGgcSGrj_jeEc14RU6b3nh7A-AGYN5dNumHzrfc9a3dus7natEfNXtN01vNL6HXpkCVa4Vnh13v2wsqp8lyyS8zLikt3Jk5NoAWJLqdwU_KLJYXMS7e5No.76SXmIhfHEi9mlZtD9XcHulm2aSDA8qL6fgOv4sJ4Lw";

  const redirectToAmazon = () => {
    window.open(amazonLink, "_blank");
  };

  return (
    <div>
      <div className="gried-head">
        <div className="products">
          <h1 className="product">Products</h1>
        </div>
       
        <div className="modal-flex">
            
              <div className="modal-right">
              <Slider {...settings}>

                <img src={abimg1} alt="" srcset="" className="modal-right-img" />
                <img src={abimg2} alt="" srcset="" className="modal-right-img"/>

                </Slider>
             
              </div>

              <div className="modal-txt">
                <h1 style={{fontSize:"28px"}}>Bilona Ghee</h1>
                <h2 style={{fontSize:"20px "}}>500ml/1000ml</h2>
                <p className="bilona-p"><span></span></p>
                

                <p className="modal-p">
                  <span>Bilona Ghee:</span> Kavlikart Desi Gir Cow A2 Bilona
                  Ghee is full of nutritive fatty acids, an ideal fat source for
                  the Indian diet. Its regular consumption enhances physical and
                  mental strength for a balanced and active lifestyle. It keeps
                  the body healthy and increases the potency of the body, bilona
                  ghee 1 litre
                </p>
                <p className="modal-p">

                  <span>100% Pure & Natural: </span>Absolutely free from
                  additives, preservatives, and artificial flavours, providing
                  you with the goodness of pure, unadulterated ghee. Packed with
                  essential nutrients and healthy fats, this ghee is known for
                  its potential health benefits, supporting digestion, contains
                  anti-inflammatory properties, immunity, and overall
                  well-being.
                </p>
                <p className="modal-p">

                  <span>Packaging:</span> Encased in a 1000ml glass jar, the
                  packaging not only preserves the freshness of the ghee but
                  also reflects Kavlikart dedication to sustainability and
                  ensures an authentic experience of this liquid gold, gir cow
                  ghee 1kg
                </p>
                <p className="modal-p">

                  <span>Milk Source:</span> Kavlikart Pure Gir Cow Fresh A2 Ghee
                  is made from milk collected from grass-fed desi Gir cows.
                  Grazing upon medicinal grasses in pastures makes the milk of
                  desi Gir cows’ nectar. Now this nectar gives us the best of
                  Bilona Ghee 1 litre
                </p>
                <p className="modal-p">

                  <span>Traditional Method:</span> Gir Cow Ghee made from bilona
                  process, Crafted using the traditional Bilona method, ensuring
                  the purest and most authentic ghee production, Involves
                  churning curd obtained from cow's milk to extract butter. Milk
                  quality is crucial for the rich flavor and nutritional
                  benefits, desi cow bilona ghee
                </p>
                <p className="modal-p">

                  <span>Cultural Significance:</span> Deeply rooted in Indian
                  culture and Ayurveda for its health benefits. Considered
                  sacred in certain rituals and ceremonies, growing popularity
                  due to the increasing demand for traditional and authentic
                  food products. Consumers seeking natural and unprocessed
                  alternatives, a2 cow desi ghee
                </p>
                <p className="modal-p">

                  <span>Aroma Flavour:</span> Has a distinct and rich aroma due
                  to the traditional method of preparation. Offers a unique,
                  nutty, and wholesome Flavour. Butter obtained is clarified
                  through a slow and careful heating process. Boiled at a low
                  temperature to retain the nutritional components.
                </p>
                <p className="modal-p">

                  <span>Recommend Usage:</span> 1 spoon on daily basis
                </p>
                <p className="modal-p">

                  Our A2 bilona Desi Ghee is best stored in a cool and dry place
                  with the lid tightly closed, danedar ghee
                </p>
                <p className="modal-p">

                  No Added Colour and Preservatives, No Added Fragrance, 100%
                  Naturally obtained Ghee
                </p>
          <div className="modal-button-submit">
                <Button text={"Buy Now"} link={redirectToAmazon}  style={{padding:"10px 0"}} />
                </div>
              </div>
            </div>

          

          

       
      </div>
    </div>
  );
};

export default Product;
