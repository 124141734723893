import React from "react";
import "../styles/Aboutghee.css";
import bilona from "../images/bilona.png";
import knowabout from "../images/know-ab.png";
import aboutb1 from "../images/aboutb1.png";
import img from "../images/img.png";
import about from "../images/about.png";
import abimg from "../images/abimg.jpg";
const Aboutghee = () => {
  return (
    <>
      <div className="about-main-first">
        <div className="about-g">
          <img src={about} alt="" srcset="" />
        </div>
        <div className="about-containers">
          <div className="about-flexs">
            <div className="about-p">
              <p className="know-p">Know about Bilona Ghee</p>

              <p className="know-secound">
                Bilona Ghee is an ancient Indian traditional method of making
                ghee, where a wooden beater called Bilona is used to churn out
                butter from the curd, and then the butter is cooked on a slow
                flame to make ghee. The process of making this ghee makes it
                unique. Bilona ghee has many benefits, it enhances the richness
                of flavours, and it contains a high amount of vitamins, and
                antioxidants, which makes you switch from regular ghee to bilona
                ghee. Bilona ghee is free from harmful chemicals. Bilona ghee
                offers a delightful nostalgic aroma.
              </p>
            </div>
            <div className="about-img">
              <img src={abimg} alt="" srcset=""  className="abimg"/>
            </div>
          </div>
        </div>
      </div>
      <div className="about-ghee-last">
        <img src={aboutb1} alt="" srcset="" />
      </div>
    </>
  );
};

export default Aboutghee;
