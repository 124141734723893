import React from "react";
import "../styles/About.css";
import aboutbg from "../images/about-bg.png";
import kavlikart from "../images/ghee.svg";
// import aboutbilona from "../images/aboutbilona.png"
const About = () => {
  return (
    <div>
      <div className="about-main">
        <h1 className="about">About Us</h1>
      </div>
      <div className="about-container">
        <div className="about-flex">
          {/* <div className="about-vidio"> */}
          <iframe
  class="about-vidio"
  src="https://www.youtube.com/embed/1ig_XJ73Jkc?si=SIxIkKwWWhqNYZjD"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
  referrerpolicy="strict-origin-when-cross-origin"
  allowfullscreen
></iframe>

          {/* </div> */}
          <div className="about-lorem">
            <p className="pfirst">Kavlikart, The Essence of Purity  </p>
            {/* <br/> */}
            <p className="psec">
              Welcome to Kavlikart, where we bring you the finest quality
              products that honor India's rich heritage and traditions. Our
              flagship offering, Gir Cow's A2 Bilona Ghee, is crafted with
              dedication and care, ensuring you receive the purest, most
              authentic ghee available. At Kavlikart, we believe in the power of
              nature and traditional methods to promote well-being. Our ghee is
              made from the milk of grass-fed desi Gir cows and produced using
              the traditional Bilona method. This process retains essential
              nutrients and provides a distinct, rich aroma and flavor. We are
              committed to purity, quality, and sustainability. Our ghee is 100%
              natural, free from additives, preservatives, and artificial
              flavors. Packaged in eco-friendly glass jars, we ensure freshness
              while reducing environmental impact. Our A2 Bilona Ghee supports
              digestion, boosts immunity, and enhances physical and mental
              strength, making it a valuable addition to your daily diet. Rooted
              in Ayurveda and Indian culture, our ghee is cherished for its
              health benefits and sacredness in rituals. Experience the goodness
              of tradition with Kavlikart. Thank you for choosing us on your
              journey to a healthier lifestyle.
            </p>
            <p className="psec"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
