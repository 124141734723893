import React from "react";
import "../styles/Home.css";
import cow from "../images/cow.png";
import gheeimg from "../images/ghee.svg";
import whatapp from "../images/whasapp.svg";
import whatappimg from "../images/whatappimg.png"
import whatimg from "../images/whatimg.png"
import Button from "./Button";

import homelast from "../images/homelast.png";
import homebanner from "../images/homebanner.png";

const Home = () => {
  const amazonLink =
    "https://www.amazon.in/bilona-gir-cow-ghee-1kg-A2-Curd-Churned-Vedic-Method/dp/B0CNXZLWY4/ref=sr_1_5?crid=1SXQOPT8PDX2V&dib=eyJ2IjoiMSJ9.tl3flArf6JEQ5TAqsCJ-MleMQSVXs1F8Hs0Hd3nt_46ooyTEE_Ht1yju3VEUHMIvnMvZ_sYNgx41xr_CTgJHkVdxTMnnHDJoO7vNvP444akyCnsfxCcGSYCjUbhPUHdJEI-MoJFPScdPgR8YVUmDLgVn6A3GywnrjyRFOxAHeQRXGaG2fOHSduGgcSGrj_jeEc14RU6b3nh7A-AGYN5dNumHzrfc9a3dus7natEfNXtN01vNL6HXpkCVa4Vnh13v2wsqp8lyyS8zLikt3Jk5NoAWJLqdwU_KLJYXMS7e5No.76SXmIhfHEi9mlZtD9XcHulm2aSDA8qL6fgOv4sJ4Lw&th=1";

  const redirectToAmazon = () => {
    window.open(amazonLink, "_blank");
  };
  return (
    <>
    <div className=" home-section" style={{overflow:"hidden"}}>
    

        <div className="cow-flex">
          <div className="div-cow"  data-aos="fade-right"       data-aos-duration="2000"> 
            <img src={cow} alt="cow" className="cow-display" />
          </div>
          <div className="experience">
            <div className="experice-flex">
            
              <div className="experice-magic "   data-aos="zoom-in"        data-aos-duration="2000">
                <div className="experice-p">
                  <p className="banner-h ">
                  Experience the tradition of pure ghee in every bite

                  </p>
                  <div className="experince-p2">
                    <p className="banner-p ">
                    At Kavlikart, Quality meets perfection of A2 Ghee which benefits your Health in many ways.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ghee" data-aos="fade-left"        data-aos-duration="2000">
                <img src={homebanner} alt="ghee" className="ghee-display" />
              </div>
            </div>
              <div className="modal-button-submit banner-button">
                <Button text={"Buy Now"} link={redirectToAmazon}  style={{padding:"10px 0"}} />
                </div>
          </div>
        </div>
      
      </div>
      
   
    <div className="home" >
        <img src={homelast} alt="home last" />
      </div>
<div className="fixed-icon">
      <a href="https://wa.me/9173828116" target="_blank" >
                    <img src={whatimg} alt="" style={{width:"50px"}}/>
                  </a>
                  </div>
    </>
  );
};

export default Home;