import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/navbar.css";
import navimg from "../images/navimg.png";
import { FaPhoneAlt } from "react-icons/fa";
import logo from "../images/logo.webp";
import phone from "../images/phone.svg";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState();
  const navRef = useRef();

  const handletoggleclick = () => {
    setMenuOpen(!menuOpen);
  };

  const navmenuclick = (scrollposition) => {
    setMenuOpen(false);
    window.scrollTo(0, scrollposition);
  };
  const handleScroll = (e ,immediate = false) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    const headerOffset = 130;
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - headerOffset;

    if (immediate) {
      window.scrollTo(0, offsetPosition);
    } else {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleOutsideClick = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };
  useEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [menuOpen]);
  const telLink = "tel:+919876543210";

  const teliphonelink = () => {
    window.open(telLink, "nopper");
  };

  return (
    <nav className="navbar" ref={navRef}>
      <div className="navbar-container">
        <Link to="/" className="logo" onClick={() => navmenuclick(0)}>
          <img src={logo} alt="Logo" className="logo-img" />
        </Link>
        <div
          id="navbar"
          className={`menu-toggle ${menuOpen ? "open" : ""}`}
          onClick={handletoggleclick}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={`nav-menu ${menuOpen ? "open fadeInAnimation" : ""}`}>
          <li>
            <a
              href="#home"
              onClick={(e) => {
                navmenuclick();
                handleScroll(e, true);
              }}
            >
              Home
            </a>
          </li>

          <li>
            <a
              href="#about"
              onClick={(e) => {
                navmenuclick();
                handleScroll(e, true);
              }}
            >
              About
            </a>
          </li>

          <li>
            <a
              href="#Product"
              onClick={(e) => {
                navmenuclick();
                handleScroll(e, true);
              }}
            >
              Product
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={(e) => {
                navmenuclick();
                handleScroll(e, true);
              }}
            >
              Contact Us
            </a>
          </li>

          <a href="tel:+919173828116">
            <img src={phone} alt="Phone" className="phone-img" />
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
