
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import About from './components/About';
import Aboutghee from './components/Aboutghee';
import Button from './components/Button';
import Contactus from './components/Contactus';
import Factor from './components/Factor';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Product from './components/Product';


function App() {
  return (
    
    <div>
      <Navbar />
      <section id="home">
        <Home />
      </section>
      <section id="about">
        <About />
      </section>
      <Aboutghee />
      
      <section id="Product">
        <Product />
      </section>
      
        <Factor />
      
      <section id="contact">
        <Contactus />
      </section>
      <Footer />
    </div>
  );
}

export default App;
